import { SVGProps } from 'react';

export function Svg(props: SVGProps<SVGSVGElement>) {
  return <svg {...props} />;
}

export function Polyline(props: SVGProps<SVGPolylineElement>) {
  return <polyline {...props} />;
}

export function G(props: SVGProps<SVGGElement>) {
  return <g {...props} />;
}

export function Circle(props: SVGProps<SVGCircleElement>) {
  return <circle {...props} />;
}

export function Line(props: SVGProps<SVGLineElement>) {
  return <line {...props} />;
}

export function Path(props: SVGProps<SVGPathElement>) {
  return <path {...props} />;
}

export function Polygon(props: SVGProps<SVGPolygonElement>) {
  return <polygon {...props} />;
}

export function Rect(props: SVGProps<SVGRectElement>) {
  return <rect {...props} />;
}
